import { AST, ParseResult } from "@effect/schema";
import * as S from "@effect/schema/Schema";
import { pipe } from "effect";
import { DateTime } from "luxon";

export const ISO8601_UTC_TIME_STRING = pipe(
  S.String,
  S.transformOrFail(S.String, {
    decode: (dateString: string, _options, ast) => {
      const date = DateTime.fromISO(dateString, { zone: "utc" });
      if (!date.zone.isUniversal) {
        return pError(ast, dateString, "A universal timezone");
      }

      const isoString = date.toUTC().toISO();
      if (isoString === null) {
        const message = `A proper ISO 8601 date time string`;
        return pError(ast, dateString, message);
      }
      return ParseResult.succeed(isoString);
    },
    encode: ParseResult.succeed,
  })
);

export class UTCDateTime extends S.Class<UTCDateTime>("UTCDateTime")({
  d: ISO8601_UTC_TIME_STRING,
}) {
  get luxon() {
    return DateTime.fromISO(this.d, { zone: "utc" });
  }

  get jsDate() {
    return this.luxon.toJSDate();
  }

  format(format: string) {
    return this.luxon.toFormat(format);
  }

  formatISO() {
    return this.luxon.toISO()!;
  }
}

const assert = (condition: boolean, message: string) => {
  if (!condition) {
    throw new Error(message);
  }
};

export namespace UTCDateTime {
  export const schema = pipe(
    ISO8601_UTC_TIME_STRING,
    S.transform(UTCDateTime, {
      decode: (d) => new UTCDateTime({ d }),
      encode: (d) => d.d,
    })
  );
  export const factory = (s: string) => {
    return S.decodeSync(schema)(s);
  };
  export const fromLuxon = (d: DateTime) => {
    const isoString = d.toUTC().toISO();
    assert(isoString !== null, "isoString is not null");

    return new UTCDateTime({ d: isoString! });
  };
}

const pError = (ast: AST.AST, dateString: string, message: string) =>
  ParseResult.fail(new ParseResult.Type(ast, dateString, message));
