import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, splitLink, httpLink } from "@trpc/client";
import React, { useState } from "react";
import superjson from "superjson";
import { makeContext } from "./makeContext";
import type { trpc } from "./trpc";
import { UTCDateTime } from "~/domain/UTCDateTime";
import { LoadCurve } from "~/domain/LoadCurve";
import * as S from "@effect/schema/Schema";
import { LDateTime } from "~/domain/LDateTime";

superjson.registerCustom(
  {
    isApplicable: (value): value is LoadCurve => value instanceof LoadCurve,
    //@ts-ignore
    serialize: S.encodeSync(LoadCurve),
    deserialize: S.decodeUnknownSync(LoadCurve),
  },
  "LoadCurve"
);

superjson.registerCustom(
  {
    isApplicable: (value): value is UTCDateTime => value instanceof UTCDateTime,
    //@ts-ignore
    serialize: S.encodeSync(UTCDateTime.schema),
    deserialize: S.decodeSync(UTCDateTime.schema),
  },
  "UTCDateTime"
);

superjson.registerCustom(
  {
    isApplicable: (value): value is LDateTime => value instanceof LDateTime,
    //@ts-ignore
    serialize: S.encodeSync(LDateTime),
    deserialize: S.decodeSync(LDateTime),
  },
  "LDateTime"
);

type TrpcReact = typeof trpc;

interface Props {
  baseApiUrl: string;
  trpc: TrpcReact;
  children: React.ReactNode;
}

const trpcContext = makeContext<TrpcReact>("TrpcReact");

export const useTrpc: () => TrpcReact = trpcContext.useContext;

export function TrpcClientProvider({ baseApiUrl, children, trpc }: Props) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false, // default: true
            retry: false, // default: true
          },
        },
      })
  );
  const [trpcClient] = useState(() => {
    const url = `${baseApiUrl}/trpc`;
    return trpc.createClient({
      transformer: superjson,
      links: [
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return true;
            // return (
            //   op.path === "extractInvoiceContracts" ||
            //   op.context.skipBatch === true
            // ); //temporary fix
          },
          // when condition is true, use normal request
          true: httpLink({
            url,
            fetch(url, options) {
              return fetch(url, {
                ...options,
                credentials: "include",
              });
            },
          }),
          // when condition is false, use batching
          false: httpBatchLink({
            url,
            fetch(url, options) {
              return fetch(url, {
                ...options,
                credentials: "include",
              });
            },
          }),
        }),
      ],
    });
  });

  return (
    <trpcContext.Provider value={trpc}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </trpc.Provider>
    </trpcContext.Provider>
  );
}
